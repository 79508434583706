import * as React from "react";

// plugin imports

// utils imports
import FetchData from "../utils/fetchData";

// svg imports

export const RouteContext = React.createContext({
	location: undefined,
});

const defaultValues = {
	menuData: undefined,
	menuDataError: false,
};

export const MenuDataContext = React.createContext(defaultValues);

export const MenuDataProvider = ({ children }) => {
	const [menuData, setMenuData] = React.useState(undefined);
	const [menuDataError, setMenuDataError] = React.useState(false);

	const { location } = React.useContext(RouteContext);
	// console.log("location: " + location.pathname);
	// console.log(process.env.GATSBY_GAPI);

	React.useEffect(() => {
		if (location.pathname.includes("/menus/")) {
			// Check location on complete site to prevent API calls outside menu pages
			// console.log("Fetching data");
			FetchData(
				process.env.GATSBY_GAPI,
				(data) => setMenuData(data),
				() => setMenuDataError(true)
			);
		}
	}, [location]);

	return (
		<MenuDataContext.Provider
			value={{
				...defaultValues,
				menuData,
				menuDataError,
			}}
		>
			{children}
		</MenuDataContext.Provider>
	);
};
