import * as React from "react";

// plugin imports
import { useTranslation } from "react-i18next";

// utils imports

// svg imports
import Altramuces from "../svg/icons/allergens/altramuces.svg";
import ApioIcon from "../svg/icons/allergens/apio.svg";
import CacahuetesIcon from "../svg/icons/allergens/cacahuetes.svg";
import CrustáceosIcon from "../svg/icons/allergens/crustáceos.svg";
import FrutosCáscaraIcon from "../svg/icons/allergens/frutosdecáscara.svg";
import GlutenIcon from "../svg/icons/allergens/gluten.svg";
import HuevosIcon from "../svg/icons/allergens/huevos.svg";
import LácteosIcon from "../svg/icons/allergens/lácteos.svg";
import MaízIcon from "../svg/icons/allergens/maíz.svg";
import MoluscosIcon from "../svg/icons/allergens/moluscos.svg";
import MostazaIcon from "../svg/icons/allergens/mostaza.svg";
import PescadoIcon from "../svg/icons/allergens/pescado.svg";
import SésamoIcon from "../svg/icons/allergens/sésamo.svg";
import SetasIcon from "../svg/icons/allergens/setas.svg";
import SojaIcon from "../svg/icons/allergens/soja.svg";
import SulfitosIcon from "../svg/icons/allergens/sulfitos.svg";

export const RouteContext = React.createContext({
	location: undefined,
});

const defaultValues = {
	allergensFilter: [],
	showPanel: false,
	allergensList: {},
};

export const AllergensContext = React.createContext(defaultValues);

export const AllergensProvider = ({ children }) => {
	const { t } = useTranslation();
	const [allergensFilter, setAllergensFilter] = React.useState([]);
	const [showPanel, setShowPanel] = React.useState(false);

	const allergensList = {
		altramuces: {
			title: t("Altramuces"),
			svg: <Altramuces className="allergenIcon" />,
		},
		apio: {
			title: t("Apio"),
			svg: <ApioIcon className="allergenIcon" />,
		},
		cacahuetes: {
			title: t("Cacahuetes"),
			svg: <CacahuetesIcon className="allergenIcon" />,
		},
		crustáceos: {
			title: t("Crustáceos"),
			svg: <CrustáceosIcon className="allergenIcon" />,
		},
		frutosdecáscara: {
			title: t("Frutos de cáscara"),
			svg: <FrutosCáscaraIcon className="allergenIcon" />,
		},
		gluten: {
			title: t("Gluten"),
			svg: <GlutenIcon className="allergenIcon" />,
		},
		huevos: {
			title: t("Huevos"),
			svg: <HuevosIcon className="allergenIcon" />,
		},
		lácteos: {
			title: t("Lácteos"),
			svg: <LácteosIcon className="allergenIcon" />,
		},
		maíz: {
			title: t("Maíz"),
			svg: <MaízIcon className="allergenIcon" />,
		},
		moluscos: {
			title: t("Moluscos"),
			svg: <MoluscosIcon className="allergenIcon" />,
		},
		mostaza: {
			title: t("Mostaza"),
			svg: <MostazaIcon className="allergenIcon" />,
		},
		pescado: {
			title: t("Pescado"),
			svg: <PescadoIcon className="allergenIcon" />,
		},
		sésamo: {
			title: t("Sésamo"),
			svg: <SésamoIcon className="allergenIcon" />,
		},
		setas: {
			title: t("Setas"),
			svg: <SetasIcon className="allergenIcon" />,
		},
		soja: {
			title: t("Soja"),
			svg: <SojaIcon className="allergenIcon" />,
		},
		sulfitos: {
			title: t("Sulfitos"),
			svg: <SulfitosIcon className="allergenIcon" />,
		},
	};

	return (
		<AllergensContext.Provider
			value={{
				...defaultValues,
				allergensFilter,
				setAllergensFilter,
				showPanel,
				setShowPanel,
				allergensList,
			}}
		>
			{children}
		</AllergensContext.Provider>
	);
};
