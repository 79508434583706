import * as React from "react";
import { MenuDataProvider, RouteContext } from "./src/context/menuDataContext";
import { AllergensProvider } from "./src/context/allergensContext";

export const wrapPageElement = ({ element, props }) => (
	<RouteContext.Provider value={{ location: props.location }}>
		<AllergensProvider>
			<MenuDataProvider>{element}</MenuDataProvider>
		</AllergensProvider>
	</RouteContext.Provider>
);
