module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-gdpr-cookies/gatsby-browser.js'),
      options: {"plugins":[],"googleTagManager":{"trackingId":"G-86NNH23WGE","anonymize":true},"environments":["production","development"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-decap-cms/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Las Lunas Soul Kitchen. Restaurante en Valencia","start_url":"/","lang":"en","background_color":"#191919","theme_color":"#fff","display":"minimal-ui","icon":"src/svg/favicon.svg","localize":[{"start_url":"/en/","lang":"en","name":"Las Lunas Soul Kitchen. Restaurant in Valencia","short_name":"Las Lunas","description":"Situated in one of the most elegant districts of Valencia, Cánovas, Restaurant Las Lunas Soul Kitchen will pleasantly surprise you with its delicious healthy dishes. There is space for everyone: meat lovers, vegans, vegetarians and just those who want to try traditional Mediterranean, and of course, Valencian cuisine."},{"start_url":"/es/","lang":"es","name":"Las Lunas Soul Kitchen. Restaurante en Valencia","short_name":"Las Lunas","description":"Situado en el corazón del barrio más elegante de Valencia, Cánovas, este restaurante ofrece una experiencia inolvidable de una cocina alternativa e innovadora, junto con los mejores sabores de la cocina internacional dando especial énfasis a los productos valencianos. También encontrarás platos veganos, vegetarianos y aptos para personas celiacas. Los mejores arroces y fideuas Valencianos. Menús del día o la carta a elegir."}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"f6fcb569b08726194bc7f250acc59f69"},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":1800,"linkImagesToOriginal":false,"backgroundColor":"none","disableBgImageOnAlpha":true,"withWebp":true,"showCaptions":false,"markdownCaptions":false,"quality":50,"withAvif":false,"loading":"lazy","decoding":"async","disableBgImage":false},
    },{
      plugin: require('../node_modules/@herob191/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"localeJsonSourceName":"locale","languages":["en","es"],"supportedLngs":["en","es","ru"],"defaultLanguage":"en","siteUrl":"https://www.laslunassoulkitchen.com/","i18nextOptions":{"interpolation":{"escapeValue":false},"keySeparator":false,"nsSeparator":false},"pages":[{"matchPath":"/dev-404-page","languages":["en"]}]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
