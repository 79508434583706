import axios from "axios"; // Import Axios

export default async function FetchData(
	csvUrl,
	callback = function () {},
	errorCallback = function () {}
) {
	// const [csvData, setCsvData] = useState([]);
	// console.log("csvURL: " + csvUrl);

	axios
		.get(csvUrl)
		.then((response) => {
			const tablasArray = {};

			for (const tabla in response.data) {
				// console.log(tabla);
				tablasArray[tabla] = [];

				for (var i = 1; i < response.data[tabla].length; i++) {
					// we start a i = 1 cause i = 0 are headers

					var row = {}; // Create a new row object for each row
					for (var j = 0; j < 100; j++) {
						if (response.data[tabla][i][j] === undefined) {
							break;
						}
						row[response.data[tabla][0][j]] = response.data[tabla][i][j];
					}
					tablasArray[tabla].push(row); // Push the row object to the output array
				}
			}

			// console.log(tablasArray);
			callback(tablasArray);
		})
		.catch((error) => {
			errorCallback(error);
			console.error("Error fetching CSV data:", error);
		});

	return;
}
